<template>
  <div>
    <r-header :title="'我的商铺'" :isLeftArrow="true" :isR="true" @back="backPage"></r-header>
    <van-tabs v-model="active" style="margin-top: 1rem;" @change="tabChange">
      <!-- <van-tab title="审核"></van-tab> -->
      <van-tab title="订单"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-bottom: 2rem;">
      <van-list
        v-model="loading"
        :finished="finished"
        offset="300"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <van-cell-group
            v-for="item in list"
            :key="item.id">
            <van-card
              num="1"
              :thumb="'/apis' + item.shopimg"
              style="margin-bottom:2px;"
            >
              <template #title>
                <span class="text-m">{{ item.shopname }}</span>
              </template>
              <template #price v-if="item.examstatus === '1' || active === 1">
                <p class="price-text">¥ <span class="money">{{ fmoney(item.shopprice, 2) }}</span></p>
              </template>
              <template #desc>
                <div v-if="item.examstatus === '1' || active === 1" class="mt-10">
                  <p>开始时间：{{ item.starttime }}</p>
                  <p>到期时间：{{item.endtime}}</p>
                </div>
                <div v-if="item.examstatus === '2'" class="mt-10">
                  <p class="money">不通过原因：{{ item.msg }}</p>
                </div>
                <div v-if="active === 0 && item.remark" class="mt-10">
                  <p  class="text">{{ item.remark }}</p>
                </div>
              </template>
              <template #footer>
                <p v-show="active === 0" style="margin-bottom:10px;">{{ item.examstatus === '0' ? '待审核' : item.examstatus === '1' ? '审核通过' : '审核不通过' }}</p>
                <div v-show="active === 1">
                  <p style="margin-bottom:10px;">{{ item.laststatus === '0' ? '待付款' : item.laststatus === '1' ? '已付款' :  item.laststatus === '2' ? '已过期' : '已取消' }}</p>
                  <!-- <van-button type="primary" size="small" v-if="item.laststatus === '1'" @click="again(item.shopid)">续费</van-button> -->
                  <!-- <van-button type="danger" size="small" v-if="item.laststatus === '0'" @click="cancelOrder(item.id)">取消支付</van-button> -->
                  <!-- <van-button style="margin-left:20px;" type="primary" size="small" v-if="item.laststatus === '0'" @click="goPay(item.id)" :disabled="disabled">支付</van-button> -->
                </div>
              </template>
            </van-card>
          </van-cell-group>
        </template>
        <van-empty v-if="!loading && list.length < 1" image="search" description="暂时没有订单" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
export default {
  name: 'StoreOrderList',
  components: {
    RHeader
  },
  data () {
    return {
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      page: 1,
      page_size: 10,
      total: 0,
      pages: 0,
      active: 0
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    backPage () {
      this.$router.go(-1)
    },
    tabChange (val) {
      this.active = val
      this.onRefresh()
      console.log(val)
    },
    onSubmit () {},
    initData () {
      this.$axios.post('/gw/userdianpuexamlist', {
        currentPage: this.page,
        pageSize: this.page_size
      }).then(res => {
        if (res.data.code === 200) {
          if (res.data.data.length === 0) {
            this.list = []
            this.finished = true
          }
          this.total = res.data.total
          const dataList = res.data.data
          if (this.page === 1) {
            this.list = dataList
          } else {
            this.list = this.list.concat(dataList)
          }
          this.loading = false
          if (this.list.length >= res.data.total) {
            this.finished = true
            this.loading = false
          }
          this.page++
        } else if (res.data.code === 100) {
          this.finished = true
          this.loading = false
          this.$router.push({
            name: 'login'
          })
        }
      }).catch(() => {
        this.finished = true
        this.loading = false
      })
    },
    initOkList () {
      this.$axios.post('/gw/zutanweidianpu', {
        shoptype: '2',
        currentPage: this.page,
        pageSize: this.page_size
      }).then(res => {
        if (res.data.code === 200) {
          if (res.data.data.length === 0) {
            this.list = []
            this.finished = true
          }
          this.total = res.data.total
          const dataList = res.data.data
          if (this.page === 1) {
            this.list = dataList
          } else {
            this.list = this.list.concat(dataList)
          }
          this.loading = false
          if (this.list.length >= res.data.total) {
            this.finished = true
            this.loading = false
          }
          this.page++
        } else if (res.data.code === 100) {
          this.finished = true
          this.loading = false
          this.$router.push({
            name: 'login'
          })
        }
      }).catch(() => {
        this.finished = true
        this.loading = false
      })
    },
    onLoad () {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          if (this.active === 0) {
            this.initData()
          } else {
            this.initOkList()
          }
          this.timer = null
        }, 500)
      }
    },
    onRefresh () {
      this.loading = true
      this.page = 1
      this.list = []
      this.refreshing = true
      this.onLoad()
      this.finished = false
      this.refreshing = false
    },
    goPay (id) {
      this.disabled = true
      if (this.disabled) {
        const redirect = 'http://www.whwwsc.com/movie/h5pay/'
        const orderId = `2*${id}`
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx63c208bb195251ca&redirect_uri=${encodeURIComponent(redirect)}&response_type=code&scope=snsapi_userinfo&state=${orderId}#wechat_redirect`
      }
    },
    again (id) {
      this.$dialog.confirm({
        title: '提示',
        message: '续费将再次发起下一个周期的租赁申请，确认此操作？'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: '/gw/dianpuinsertexam2',
          data: {
            dianpuid: id
          }
        }).then(res => {
          if (res.data.code === 200) {
            this.active = 0
            this.onRefresh()
            this.$toast({
              type: 'success',
              message: '已成功'
            })
          }
        })
      }).catch(() => {
        // on cancel
      })
    },
    cancelOrder (id) {
      this.$dialog.confirm({
        title: '提示',
        message: '您即将取消支付，确认取消吗？'
      }).then(() => {
        this.$axios({
          method: 'post',
          url: '/gw/cancel',
          data: {
            orderid: id
          }
        }).then(res => {
          if (res.data.code === 200) {
            this.onRefresh()
            this.$toast({
              type: 'success',
              message: '已取消支付'
            })
          }
        })
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>

<style lang="less" scoped>
.price-text{
  font-size: 14px;
  .money{
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
